import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from './servicios/login.service';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  Event,
  ActivatedRoute
} from '@angular/router';
import * as dayjs from 'dayjs';
import { RentacarService } from './servicios/autorreserva/rentacar/rentacar.service';
import { ServicioGeneralService } from './servicios/servicio-general.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Funciones } from 'src/assets/funcionesComunes';
import { AuthService } from './servicios/auth.service';
import Swal from 'sweetalert2';
import { first } from 'rxjs/internal/operators/first';
import { TranslateService } from '@ngx-translate/core';
import flatpickr from 'flatpickr';
import FlatpickrLanguages from 'flatpickr/dist/l10n';
import { PrimeNGConfig } from 'primeng/api';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public propsSidebar$ = this.servicioGeneral.propsSidebar$;
  userLoggedIn: boolean;
  info_user: any;
  info_user_admin: any;
  loading = false;
  private contadorCaducarSesion;
  private suscripcionTiempoSesion: Subscription;
  private suscripcionLenguaje: Subscription;
  private _suscripciones: Subscription[] = [];

  constructor(
    private loginService: LoginService,
    private router: Router,
    private rentaCarService: RentacarService,
    private servicioGeneral: ServicioGeneralService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private translate: TranslateService,
    private config: PrimeNGConfig
  ) {
    this.suscribirLenguaje();

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.deleteStates(event);
          this.servicioGeneral.setMostrarSidebar(false);
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    //#region Control de sesión caducada cuando se reabre el navegador
    let actionTemp = dayjs(localStorage.getItem("ActionTemp"), 'DD-MM-YYYY HH:mm:ss');
    let ahora = dayjs();

    if (ahora.isAfter(actionTemp.add(600, 'seconds'))) {
      this.loginService.GetLogOut(true);
    }
    //#endregion

    this.servicioGeneral.setNavegadorIE(Funciones.detectIE());
    this.suscripcionTiempoSesion = this.authService.tiempoSesion$.subscribe((tiempo) => {
      if (!tiempo) {
        this.authService.setTiempoSesion();
      } else {
        if (this._suscripciones.length == 0) {
          this.suscribirUltimaAccion();
          this.suscribirEstaLogueado();
          this.comprobarSesionCaducada();
          this.suscribirLoading();
          this.suscribirMostrarSidebar();
        }
      }
    });
  }

  cambiarIdiomaFlatpickr(idioma: string) {
    const lenguajeParseado = idioma.split('-')[0];
    flatpickr.localize(FlatpickrLanguages[lenguajeParseado]);
  }

  ngOnDestroy(): void {
    this.suscripcionLenguaje.unsubscribe();
    this.suscripcionTiempoSesion.unsubscribe();
    this._suscripciones.forEach((suscripcion) => {
      suscripcion.unsubscribe();
    });
  }

  cambiarIdioma(idioma) {
    this.translate.use(idioma);
  }

  onActivate(event) {
    let pos = window.pageYOffset;
    if (pos > 0) {
      window.scrollTo(0, 0);
    }
  }

  deleteStates(event) {
    if (event.url !== '/autorreserva/rentacar' && event.url !== '/autorreserva/rentacar/prereserva') {
      this.rentaCarService.deleteStateRentACar();
    }
  }

  suscribirMostrarSidebar() {
    this._suscripciones.push(
      this.servicioGeneral.propsSidebar$.subscribe((valor) => {
        if (valor.mostrar === true) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      })
    );
  }

  suscribirLenguaje() {
    this.suscripcionLenguaje = this.servicioGeneral.lenguaje$.subscribe((lenguaje) => {
      this.translate.use(lenguaje).pipe(
        switchMap(() => this.translate.get("primeng").pipe(
          switchMap(dataPrimeng => this.translate.get("shared.dias").pipe(
            switchMap(dataDias => this.translate.get("shared.mes").pipe(
              map(dataMes => [dataPrimeng, dataDias, dataMes]))
            )
          )))
        )).subscribe(([dataPrimeng, dataDias, dataMes]) => {
          const ordenDias = ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sabado"];
          const ordenMeses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

          dataPrimeng.dayNames = ordenDias.map(x => dataDias.entero[x]);
          dataPrimeng.dayNamesShort = ordenDias.map(x => dataDias.corto[x]);
          dataPrimeng.dayNamesMin = ordenDias.map(x => dataDias.min[x]);

          dataPrimeng.monthNames = ordenMeses.map(x => dataMes.entero[x]);
          dataPrimeng.monthNamesShort = ordenMeses.map(x => dataMes.corto[x]);

          this.config.setTranslation(dataPrimeng);
        });

      this.cambiarIdiomaFlatpickr(lenguaje);
    });
  }

  suscribirUltimaAccion() {
    const LSUltimaAccion = localStorage.getItem('ActionTemp');
    if (LSUltimaAccion) this.authService.setUltimaAccion(dayjs(LSUltimaAccion, 'DD-MM-YYYY HH:mm:ss'));
    this._suscripciones.push(
      this.authService.ultimaAccion$.subscribe(() => {
        if (!environment.demo) {
          this.mostrarModalSigueActivo();
        }
      })
    );
  }

  mostrarModalSigueActivo() {
    if (this.contadorCaducarSesion) clearTimeout(this.contadorCaducarSesion);
    if (!this.authService.SesionCaducada) {
      this.contadorCaducarSesion = setTimeout(() => {
        Swal.fire({
          customClass: {container: 'swal-z-index-9999'},
          title: this.translate.instant('modal-sigue-activo.titulo'),
          html: this.translate.instant('modal-sigue-activo.texto'),
          timer: this.authService.tiempoSesion - this.authService.getTiempoSesionGastado(),
          timerProgressBar: true,
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: this.translate.instant('modal-sigue-activo.seguir-activo'),
          cancelButtonText: this.translate.instant('modal-sigue-activo.cerrar-sesion'),
          cancelButtonColor: '#F44336'
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.loginService.GetLogOut(true);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.loginService.GetLogOut(false);
          } else if (result.value) {
            this.authService
              .mantenerSesionActiva()
              .pipe(first())
              .subscribe(
                () => { },
                () => {
                  this.loginService.GetLogOut(true);
                }
              );
          }
        });
      }, this.authService.tiempoSesion - 120000 - this.authService.getTiempoSesionGastado());
    }
  }

  suscribirEstaLogueado() {
    this._suscripciones.push(
      this.loginService.getLoggedIn().subscribe((logueado: any) => {
        this.userLoggedIn = logueado;
      })
    );
    this.info_user = JSON.parse(localStorage.getItem('info_user'));
    this.info_user_admin = JSON.parse(localStorage.getItem('info_user_admin'));
    this.loginService.setLoggedIn(this.info_user || this.info_user_admin ? true : false);
  }

  comprobarSesionCaducada() {
    this._suscripciones.push(
      this.router.events.subscribe(() => {
        if (this.userLoggedIn) {
          if (this.authService.SesionCaducada) {
            this.loginService.GetLogOut(true);
          }
        }
      })
    );
  }

  suscribirLoading() {
    this._suscripciones.push(
      this.servicioGeneral.loading$.subscribe((valor) => (valor ? this.spinner.show() : this.spinner.hide()))
    );
  }
}
